import React from 'react';
import { Images, useGlobalText, useModal } from 'utils';
import { buttonsStyle } from './styles';
import { CsvModal } from '../modal';
import { reportServices } from '../../store';
import { MinLoader } from '../loader';

export const CsvFile = ({ params }) => {
  const classes = buttonsStyle();
  const globalText = useGlobalText();
  const { open } = useModal();
  const [loader, setLoader] = React.useState(false);

  const handleDownload = async () => {
    if (params?.noModal) {
      setLoader(true);
      let response = '';
      if (params?.type === 'txns') {
        response = await reportServices.getLoadTxnReports(params, params?.loadId);
      }
      try {
        const fileName = params?.type === 'customerPayment' ? inputs?.status === 'OVERVIEW' ? 'customerPayment' : 'customerPaymentDetails' : params?.type;
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${fileName}.csv`);
        document.body.appendChild(link);
        link.click();
        setLoader(false);
        close();

      } catch (err) {
        setLoader(false);
      }
    } else {
      open(<CsvModal params={params} />);
    }
  };

  return (
    <div>
      <button
        className={classes.csvExportBtn}
        onClick={handleDownload}
      >
        {loader ?
          <MinLoader color={'#00C851'} position={'relative'} />
          :
          <img src={Images.download} alt="icon" />
        }
        <p
          className={globalText.downloadText}
        >
          Export CSV File
        </p>
      </button>
    </div>
  );
};