import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  carrierActions,
  httpRequestsOnErrorsActions,
  httpRequestsOnSuccessActions, mcActions,
} from 'store';
import {
  createInputsWrapper,
  EmailValidator,
  ErrorText,
  FindErrorItem,
  FindLoad,
  FindSuccessItem, PermissionsList, RolePermission,
  useGlobalStyles,
  useGlobalText, userType,
} from 'utils';
import {
  CreateChancel,
  Line,
  ValidationInput,
  EditSaveButtons,
  SelectInput,
  MaskInput, SelectTypeAutocomplete, FormatAddress,
} from 'components';
import moment from 'moment';
import { RadioButtons } from './radioButtons';
import { company, docker } from '../../carrierInfo/core';
import { CREATE_CARRIER, EDIT_CARRIER } from '../../../../store/carriers/carrier.types';
import { stateList } from '../../../../utils/jsons/country';

const CREATE_TYPE = CREATE_CARRIER;
const EDIT_TYPE = EDIT_CARRIER;

export const EditCarrierInputs = ({ handleChangeName, info }) => {
  const { mcsList } = useSelector((state) => ({
    mcsList: state.mcs.mcsList,
  }));
  const mcFilteredList = mcsList?.mcs?.filter((i) => i?.authorityType === 'CARRIER');
  const globalText = useGlobalText();
  const globalStyles = useGlobalStyles();
  const globalScreens = createInputsWrapper();
  const dispatch = useDispatch();
  const history = useHistory();
  const [disables, setDisabled] = useState(!!info);
  const [shippingAddress, setShippingAddress] = useState(info?.shippingAddress ? info?.shippingAddress : {});
  const [mailingAddress, setMailingAddress] = useState(info?.mailingAddress ? info?.mailingAddress : {});
  const [newAllAddress, setNewAllAddress] = useState({});
  const [address, setAddress] = useState(info?.address ? info?.address : {});
  const [error, setError] = useState('');
  const [inputs, setInputs] = useState(info ? { ...info } : {});
  const [mailingShipping, setMailingShipping] = useState('Other');
  const [email, setEmail] = useState('');
  const [remittanceEmail, setRemittancev] = useState('');
  const actionType = info ? EDIT_TYPE : CREATE_TYPE;
  const loader = FindLoad(CREATE_TYPE);
  const success = FindSuccessItem(EDIT_TYPE);
  const backError = FindErrorItem(actionType);
  const errorText = backError?.error ? backError?.error : Array.isArray(backError?.error) ? backError?.error[0] : '';

  useEffect(() => {
    if (info?.isFactored) {
      dispatch(mcActions.getMcs());
    }
    if (info?.mailingAddress?.formattedAddress === info?.shippingAddress?.formattedAddress) {
      setMailingShipping('Same');
      setMailingAddress(info?.shippingAddress);
      setNewAllAddress(info?.shippingAddress);
    } else {
      setMailingShipping('Other');
    }
  }, [info]);

  const removeError = () => {
    errorText && dispatch(httpRequestsOnErrorsActions.removeError(actionType));
  };

  useEffect(() => {
    if (success) {
      dispatch(httpRequestsOnSuccessActions.removeSuccess('EDIT_CARRIER'));
      setDisabled(true);
    }
  }, [success]);

  useEffect(() => {
    return () => {
      removeError();
    };
  }, []);

  const handleChange = e => {
    setInputs(prevState => ({ ...prevState, [e.target.name]: e.target.value }));
    error === e.target.name && setError('');
    e.target.name === 'companyName' && handleChangeName(e.target.value);
    removeError();
    if (errorText === 'A Carrier with this Authority Number already exists.') {
      dispatch(httpRequestsOnErrorsActions.removeError('CARRIER_AUTHORITY_EXIST'));
    }
  };

  const handleCreate = () => {
    const phoneNumber = inputs?.phone && parseInt(inputs?.phone.replace(/[^0-9]/g, '')) + '';
    const secPhoneNumber = inputs?.secondaryPhone && parseInt(inputs?.secondaryPhone.replace(/[^0-9]/g, '')) + '';
    const checkFactored = info?.isFactored ? !!inputs.loadMC : true;

    const data = {
      'firstName': inputs.firstName,
      'lastName': inputs.lastName,
      'email': inputs.email,
      'remittanceEmail': inputs.remittanceEmail,
      'phone': phoneNumber,
      'birthdate': inputs.birthdate ? new Date(inputs.birthdate).getTime() : null,
      'dl': inputs.dl,
      'dlState': inputs.dlState,
      'shippingAddress': shippingAddress,
      'mailingAddress': mailingShipping === 'Same' ? newAllAddress : mailingAddress,
      'companyName': inputs.companyName,
      'companyType': inputs.companyType,
      'docketType': inputs.docketType,
      'docketNumber': inputs.docketNumber,
      'taxId': inputs.taxId,
      'address': address,
    };
    inputs?.dot ? data.dot = inputs?.dot : '';
    inputs?.insuranceExpiry ? data.insuranceExpiry = inputs?.insuranceExpiry : '';
    data.secondaryPhone = inputs?.secondaryPhone ? secPhoneNumber : null;

    if (info?.isFactored) {
      data.loadMC = inputs?.loadMC;
    }

    const id = info && info.id;
    if (inputs.firstName && inputs.lastName && inputs.email && !email && inputs.companyName && inputs.docketType && inputs.docketNumber && !remittanceEmail && checkFactored) {
      dispatch(carrierActions.editCarrier(id, data));
    } else {
      setError(
        !inputs.firstName ? 'firstName' :
          !inputs.lastName ? 'lastName' :
            !inputs.email ? 'email' :
              !inputs.companyName ? 'companyName' :
                !inputs.docketType ? 'docketType' :
                  !inputs.docketNumber ? 'docketNumber' :
                    !checkFactored ? 'loadMC' :
                      '',
      );
    }
  };

  const handleSetShippingAddress = (ev) => {
    setShippingAddress(ev);
    if (error === 'shippingAddress') setError('');

    if (mailingShipping === 'Same') {
      setMailingShipping('Other');
      setMailingAddress({});
      setNewAllAddress({});
    }
  };

  const handleSetAddress = (ev) => {
    setAddress(ev);
    if (error === 'address') setError('');
  };

  const handleMailingAddress = (ev) => {
    setMailingAddress(ev);
    removeError();
    if (error === 'mailingAddress') setError('');
  };

  const handleSelectMailingAddress = (ev) => {
    setMailingShipping(ev);
    if (ev === 'Other') {
      setMailingAddress({});
      setNewAllAddress({});
    } else {
      setMailingAddress(shippingAddress);
      setNewAllAddress(shippingAddress);
    }
  };

  const handleChancel = () => {
    setDisabled(true);
    setInputs({ ...info });
    setShippingAddress(info?.shippingAddress);
    setMailingAddress(info?.mailingAddress);
    setAddress(info?.address);
    setError('');
    setEmail('');
    removeError();
  };

  return (
    <div className={globalScreens.createInputsWrapper}>

      <div className="space-between">
        <div className="align-center">
          {!info && <p className={globalText.title}>Carrier Information</p>}
        </div>
        {info && RolePermission([PermissionsList.CREATE_UPDATE_CARRIER?.code]) &&
          <EditSaveButtons
            margin={true}
            type={'EDIT_CARRIER'}
            handleChancel={handleChancel}
            handleSetEdit={() => setDisabled(false)}
            handleSaveInfo={handleCreate}
          />}
      </div>
      <div className={globalStyles.flexAble}>
        {info && <Line height={'auto'} />}
        <div className={globalStyles.fullWidth}>
          <div className={globalScreens.basicInfo}>
            <p className={globalText.smallText}>Basic Information</p>
            <div className={globalScreens.basicInfoInputs}>
              <ValidationInput
                className={info?.firstName && globalStyles.inputTextFieldBlue}
                style={globalStyles.simpleInput}
                variant={'outlined'}
                name={'firstName'}
                type={'text'}
                label={'First Name*'}
                typeError={error === 'firstName' ? ErrorText.field : ''}
                onChange={handleChange}
                value={inputs.firstName}
                disabled={disables}
              />
              <ValidationInput
                className={info?.lastName && globalStyles.inputTextFieldBlue}
                style={globalStyles.simpleInput}
                variant={'outlined'}
                name={'lastName'}
                type={'text'}
                label={'Last Name*'}
                typeError={error === 'lastName' ? ErrorText.field : ''}
                onChange={handleChange}
                value={inputs.lastName}
                disabled={disables}
              />
              {userType === 'ADMIN' &&
                <ValidationInput
                  className={info?.birthdate && globalStyles.inputTextFieldBlue}
                  style={userType === 'ADMIN' ? globalStyles.simpleInput : globalStyles.simpleInputNoRight}
                  variant={'outlined'}
                  name={'birthdate'}
                  type={'date'}
                  typeError={error === 'birthdate' ? ErrorText.field : ''}
                  label={'Date of Birth'}
                  onChange={handleChange}
                  value={info?.birthdate ? moment(inputs.birthdate).format('YYYY-MM-DD') : inputs?.birthdate}
                  disabled={disables}
                />
              }
              <MaskInput
                styles={{ marginRight: 0 }}
                name="phone"
                label="Phone Number"
                value={inputs.phone}
                disables={disables}
                handleChange={handleChange}
                info={info?.phone}
                error={
                  error === 'phone' ? ErrorText.field :
                    errorText === 'phone must be a valid phone number' && ErrorText.phone}
              />
            </div>

            <div className={globalScreens.basicInfoInputs}>
              <MaskInput
                name="secondaryPhone"
                label="Secondary Number"
                value={inputs?.secondaryPhone}
                disables={disables}
                handleChange={handleChange}
                info={info?.secondaryPhone}
                error={
                  error === 'secondaryPhone' ? ErrorText.field :
                    errorText === 'secondaryPhone must be a valid phone number' && ErrorText.phone}
              />

              {userType === 'ADMIN' &&
                <ValidationInput
                  className={info?.dl && globalStyles.inputTextFieldBlue}
                  style={globalStyles.simpleInput}
                  variant={'outlined'}
                  name={'dl'}
                  type={'text'}
                  label={'Driver’s License'}
                  typeError={error === 'dl' ? ErrorText.field : ''}
                  onChange={handleChange}
                  value={inputs?.dl}
                  disabled={disables}
                />
              }
              {userType === 'ADMIN' &&
                <SelectInput
                  className={info?.dlState && globalStyles.inputTextFieldBlue}
                  style={globalStyles.simpleInput}
                  disabled={disables}
                  name={'dlState'}
                  label={'Issuing State'}
                  handleSelect={handleChange}
                  value={inputs?.dlState}
                  list={stateList}
                  typeError={error === 'dlState' ? ErrorText.field : ''}
                  type={'id'}
                />
              }
              <ValidationInput
                className={info?.email && globalStyles.inputTextFieldBlue}
                validator={EmailValidator}
                variant={'outlined'}
                name={'email'}
                type={'email'}
                label={'Email Address*'}
                typeError={
                  error === 'email' ? ErrorText.field :
                    email === 'Not valid email' ? 'Not valid email' :
                      errorText === 'Carrier already exists' ? 'Carrier already exists' :
                        ''}
                sendBoolean={(bool) => bool === true ? setEmail('Not valid email') : setEmail('')}
                onChange={handleChange}
                value={inputs?.email}
                disabled={disables}
              />
            </div>

            <div className="inputs-gap">
              <div style={{ width: '50%' }}>
                <ValidationInput
                  className={info?.remittanceEmail && globalStyles.inputTextFieldBlue}
                  style={globalStyles.simpleInput}
                  validator={EmailValidator}
                  variant={'outlined'}
                  name={'remittanceEmail'}
                  type={'email'}
                  label={'Remittance Email'}
                  typeError={error === 'remittanceEmail' ? ErrorText.field : remittanceEmail === 'Not valid email' ? 'Not valid email' : ''}
                  sendBoolean={(bool) => bool === true ? setRemittancev('Not valid email') : setRemittancev('')}
                  onChange={handleChange}
                  value={inputs?.remittanceEmail}
                  disabled={disables}
                />
              </div>
              <div style={{ width: '50%' }}>
                {info?.isFactored &&
                  <>
                    {mcFilteredList?.length ?
                      <SelectTypeAutocomplete
                        style={globalStyles.simpleInput}
                        name={'loadMC'}
                        label={'name'}
                        type={'mcNumber'}
                        title={'Load MC Number*'}
                        handleSelect={handleChange}
                        defaultValue={inputs?.loadMC}
                        list={mcFilteredList}
                        disabled={disables}
                        error={error}
                        right={'0'}
                      />
                      :
                      <SelectTypeAutocomplete
                        style={globalStyles.simpleInput}
                        name={'loadMC'}
                        title={'Load MC Number*'}
                        list={[]}
                        disabled={disables}
                        right={'0'}
                      />
                    }
                  </>
                }
              </div>
            </div>
          </div>

          {userType === 'ADMIN' &&
            <div className={globalScreens.basicInfo}>
              <p className={globalText.smallText}>Address</p>
              <div className={globalScreens.basicInfoInputs}>
                <FormatAddress
                  placeholder={'Physical Address'}
                  notRequired={true}
                  noBlue={!info?.shippingAddress}
                  setCurrentAddress={handleSetShippingAddress}
                  selectedAddress={info?.shippingAddress}
                  errorBoolean={error === 'shippingAddress'}
                  disabled={disables}
                />
              </div>
            </div>
          }

          {userType === 'ADMIN' &&
            <div className={globalScreens.basicInfo}>
              <p className={globalText.smallText}>Mailing Address</p>
              <RadioButtons
                value={mailingShipping}
                handleSelect={handleSelectMailingAddress}
                disabled={disables}
              />
              <div className={globalScreens.basicInfoInputs}>
                <FormatAddress
                  placeholder={'Physical Address'}
                  notRequired={true}
                  noBlue={!info?.mailingAddress}
                  setCurrentAddress={handleMailingAddress}
                  selectedAddress={mailingShipping === 'Same' ? newAllAddress : info?.mailingAddress}
                  errorBoolean={error === 'mailingAddress'}
                  disabled={disables}
                />
              </div>
            </div>
          }

          <div className={globalScreens.basicInfo}>
            <p className={globalText.title}>Company Information</p>

            <div className={globalScreens.basicInfo}>
              <p className={globalText.smallText}>Basic Information</p>
              <div className={globalScreens.basicInfoInputs}>
                <ValidationInput
                  className={info?.companyName && globalStyles.inputTextFieldBlue}
                  style={globalStyles.simpleInput}
                  variant={'outlined'}
                  name={'companyName'}
                  type={'text'}
                  label={'Company Name*'}
                  typeError={error === 'companyName' ? ErrorText.field : ''}
                  onChange={handleChange}
                  value={inputs?.companyName}
                  disabled={disables}
                />
                <SelectInput
                  className={info?.companyType && globalStyles.inputTextFieldBlue}
                  style={globalStyles.simpleInput}
                  name={'companyType'}
                  label={'Company Type'}
                  handleSelect={handleChange}
                  value={inputs?.companyType}
                  list={company}
                  typeError={error === 'companyType' ? ErrorText.field : ''}
                  type={'id'}
                  disabled={disables}
                />
                <SelectInput
                  className={info?.docketType && globalStyles.inputTextFieldBlue}
                  name={'docketType'}
                  label={'Type*'}
                  handleSelect={handleChange}
                  value={inputs?.docketType}
                  list={docker}
                  typeError={error === 'docketType' ? ErrorText.field : ''}
                  type={'id'}
                  disabled={disables}
                />
              </div>
            </div>

            <div className={globalScreens.basicInfoInputs}>
              <ValidationInput
                className={info?.docketNumber && globalStyles.inputTextFieldBlue}
                style={globalStyles.simpleInput}
                variant={'outlined'}
                name={'docketNumber'}
                label={'Authority Number*'}
                type={'text'}
                typeError={
                  error === 'docketNumber' ? ErrorText.field :
                    errorText === 'A Carrier with this Authority Number already exists.' ? 'A Carrier with this Authority Number already exists.' :
                      ''}
                onChange={handleChange}
                value={inputs?.docketNumber}
                disabled={disables}
                Length={20}
              />
              {userType === 'ADMIN' &&
                <ValidationInput
                  className={info?.taxId && globalStyles.inputTextFieldBlue}
                  style={globalStyles.simpleInput}
                  variant={'outlined'}
                  name={'taxId'}
                  label={'Tax ID'}
                  typeError={error === 'taxId' ? ErrorText.field : ''}
                  onChange={handleChange}
                  value={inputs?.taxId}
                  disabled={disables}
                />
              }
              <ValidationInput
                className={info?.dot && globalStyles.inputTextFieldBlue}
                style={globalStyles.simpleInput}
                variant={'outlined'}
                name={'dot'}
                type={'number'}
                label={'USDOT Number'}
                typeError={error === 'dot' ? ErrorText.field : ''}
                onChange={handleChange}
                value={inputs?.dot}
                disabled={disables}
              />
              <ValidationInput
                className={info?.insuranceExpiry && globalStyles.inputTextFieldBlue}
                variant={'outlined'}
                name={'insuranceExpiry'}
                label={'Insurance Expiration Date'}
                type={'date'}
                typeError={error === 'insuranceExpiry' ? ErrorText.field : ''}
                onChange={handleChange}
                value={info?.insuranceExpiry ? moment(inputs.insuranceExpiry).format('YYYY-MM-DD') : inputs?.insuranceExpiry}
                disabled={disables}
              />

            </div>
          </div>

          <div className={globalScreens.basicInfo}>
            <p className={globalText.smallText}>Company Address</p>
            <div className={globalScreens.basicInfoInputs}>
              <FormatAddress
                placeholder={'Physical Address'}
                notRequired={true}
                noBlue={!info?.address}
                setCurrentAddress={handleSetAddress}
                selectedAddress={info?.address}
                errorBoolean={error === 'address'}
                disabled={disables}
              />
            </div>
          </div>
        </div>
      </div>

      <p>{error === 'Input is not field' ? error : ''}</p>

      {!info &&
        <CreateChancel
          loader={!!loader.length}
          classes={globalStyles.buttonsStyle}
          create={'Create'}
          chancel={'Cancel'}
          onCreate={handleCreate}
          onClose={() => history.push('/carriers')}
        />
      }
    </div>
  );
};
