import React from 'react';
import { DateRow, IconAndTitle, MinLoader, PriceRow, TextRow } from 'components';
import { Colors, Images, isFactored, PermissionsList, RolePermission, userType } from 'utils';
import { Button } from '@material-ui/core';
import { isAdmin } from '../../utils/constants';

export const creditHead = [
  { name: 'displayId', title: 'Load ID', custom: false },
  { name: '', title: 'Start Date', icon: 'date' },
  { name: 'customer', nameRow: 'name', title: 'Customer', custom: false },
  !isFactored &&
  { name: 'agent', nameRow: 'name', title: 'Agent', custom: false },
  !isFactored &&
  { name: 'branch', nameRow: 'name', title: 'Branch', custom: false },
  !isFactored &&
  { name: 'office', nameRow: 'name', title: 'Office', custom: false },
  { name: 'customerRate', title: 'Customer Rate', custom: false, smallSize: 6 },
  RolePermission([PermissionsList.ALL_ACCESS?.code, PermissionsList.ACCEPT_DENY_LOAD?.code, PermissionsList.CREDIT_TAB?.code]) &&
  { name: '', title: 'Action ', custom: false },
];

export const creditBody = [
  { rowText: (item) => <IconAndTitle icon={Images.loadOutline} title={item?.displayId} /> },
  { rowText: (item) => <DateRow date={item?.createdAt} /> },
  { rowText: (item) => <TextRow name={item?.customer?.name} textWidth={12} /> },
  !isFactored &&
  { rowText: (item) => <TextRow name={item?.agent?.name} textWidth={12} /> },
  !isFactored &&
  { rowText: (item) => <TextRow name={item?.branch?.name} textWidth={12} /> },
  !isFactored &&
  { rowText: (item) => <TextRow name={item?.office?.name} textWidth={12} /> },
  { rowText: (item) => <PriceRow info={item?.customerRate} /> },
  RolePermission([PermissionsList.ALL_ACCESS?.code, PermissionsList.ACCEPT_DENY_LOAD?.code, PermissionsList.CREDIT_TAB?.code]) &&
  {

    button: (item, info, load) =>
      <>
        {load ?
          <div className="center-load">
            <MinLoader style={{ margin: 0 }} position={'relative'} color={Colors.ThemeBlue} />
          </div>
          :
          <div className="flex">
            <Button style={{ padding: 0, minWidth: '50px', height: '20px' }} onClick={() => item('DISAPPROVE')}>
              <img src={Images.clear} alt="icons" />
            </Button>

            <Button style={{ padding: 0, minWidth: '50px', height: '20px' }} onClick={() => item('APPROVE')}>
              <img src={Images.approveIcon} alt="icons" />
            </Button>
          </div>
        }
      </>,

    notClickable: true,
  },
];

export const rejectedHead = [
  { name: 'displayId', title: 'Load ID', custom: false },
  { name: '', title: 'Start Date', icon: 'date' },
  { name: 'customer', nameRow: 'name', title: 'Customer', custom: false },
  !isFactored &&
  { name: 'agent', nameRow: 'name', title: 'Agent', custom: false },
  !isFactored &&
  { name: 'branch', nameRow: 'name', title: 'Branch', custom: false },
  !isFactored &&
  { name: 'office', nameRow: 'name', title: 'Office', custom: false },
  { name: 'customerRate', title: 'Customer Rate', custom: false, smallSize: 6 },
  (RolePermission(null, 'officeOrBranchManager') || isFactored) &&
  { name: '', title: 'Actions ', custom: false },
];

export const rejectedBody = [
  { rowText: (item) => <IconAndTitle icon={Images.loadOutline} title={item?.displayId} /> },
  { rowText: (item) => <DateRow date={item?.createdAt} /> },
  { rowText: (item) => <TextRow name={item?.customer?.name} textWidth={12} /> },
  !isFactored &&
  { rowText: (item) => <TextRow name={item?.agent?.name} textWidth={12} /> },
  !isFactored &&
  { rowText: (item) => <TextRow name={item?.branch?.name} textWidth={12} /> },
  !isFactored &&
  { rowText: (item) => <TextRow name={item?.office?.name} textWidth={12} /> },
  { rowText: (item) => <PriceRow info={item?.customerRate} /> },
  (RolePermission(null, 'officeOrBranchManager') || isFactored) &&
  {
    button: (item, info, load) => <div style={{height:'32px', display:'flex'}}>
      {load ?
        <div className="center-load">
          <MinLoader style={{ margin: 0 }} position={'relative'} color={Colors.ThemeBlue} />
        </div>
        :
        <div className="align-center">
          <button
            onClick={() => item('CONSIGN')}
            className={'consign'}
          >
            Consign
          </button>
          <Button style={{ padding: 0, minWidth: '50px', height: '20px' }} onClick={() => item('NOTCONSIGN')}>
            <img src={Images.clear} alt="icons" />
          </Button>
        </div>
      }
    </div>,
    notClickable: true,
  },
];

export const consignRequestHead = [
  { name: 'displayId', title: 'Load ID', custom: false },
  { name: '', title: 'Start Date', icon: 'date' },
  { name: 'customer', nameRow: 'name', title: 'Customer', custom: false },
  !isFactored &&
  { name: 'agent', nameRow: 'name', title: 'Agent', custom: false },
  !isFactored &&
  { name: 'branch', nameRow: 'name', title: 'Branch', custom: false },
  !isFactored &&
  { name: 'office', nameRow: 'name', title: 'Office', custom: false },
  { name: 'customerRate', title: 'Customer Rate', custom: false, smallSize: 6 },
  RolePermission([PermissionsList.ALL_ACCESS?.code, PermissionsList.ACCEPT_DENY_CONSIGN_LOAD?.code]) &&
  { name: '', title: 'Actions ', custom: false },
];

export const consignRequestBody = [
  { rowText: (item) => <IconAndTitle icon={Images.loadOutline} title={item?.displayId} /> },
  { rowText: (item) => <DateRow date={item?.createdAt} /> },
  { rowText: (item) => <TextRow name={item?.customer?.name} textWidth={12} /> },
  !isFactored &&
  { rowText: (item) => <TextRow name={item?.agent?.name} textWidth={12} /> },
  !isFactored &&
  { rowText: (item) => <TextRow name={item?.branch?.name} textWidth={12} /> },
  !isFactored &&
  { rowText: (item) => <TextRow name={item?.office?.name} textWidth={12} /> },
  { rowText: (item) => <PriceRow info={item?.customerRate} /> },
  RolePermission([PermissionsList.ALL_ACCESS?.code, PermissionsList.ACCEPT_DENY_CONSIGN_LOAD?.code]) &&
  {
    button: (item, info, load) =>
      <>
        {load ?
          <div className="center-load">
            <MinLoader style={{ margin: 0 }} position={'relative'} color={Colors.ThemeBlue} />
          </div>
          :
          <div className="flex">
            <Button style={{ padding: 0, minWidth: '50px', height: '20px' }} onClick={() => item('DISAPPROVE')}>
              <img src={Images.clear} alt="icons" />
            </Button>

            <Button style={{ padding: 0, minWidth: '50px', height: '20px' }} onClick={() => item('APPROVE')}>
              <img src={Images.approveIcon} alt="icons" />
            </Button>
          </div>
        }
      </>,
    notClickable: true,
  },
];

export const consignHead = [
  { name: 'displayId', title: 'Load ID', custom: false },
  { name: '', title: 'Start Date', icon: 'date' },
  { name: 'customer', nameRow: 'name', title: 'Customer', custom: false },
  !isFactored &&
  { name: 'agent', nameRow: 'name', title: 'Agent', custom: false },
  !isFactored &&
  { name: 'branch', nameRow: 'name', title: 'Branch', custom: false },
  !isFactored &&
  { name: 'office', nameRow: 'name', title: 'Office', custom: false },
  { name: 'customerRate', title: 'Customer Rate', custom: false, smallSize: 6 },
];

export const consignBody = [
  { rowText: (item) => <IconAndTitle icon={Images.loadOutline} title={item?.displayId} /> },
  { rowText: (item) => <DateRow date={item?.createdAt} /> },
  { rowText: (item) => <TextRow name={item?.customer?.name} textWidth={12} /> },
  !isFactored &&
  { rowText: (item) => <TextRow name={item?.agent?.name} textWidth={12} /> },
  !isFactored &&
  { rowText: (item) => <TextRow name={item?.branch?.name} textWidth={12} /> },
  !isFactored &&
  { rowText: (item) => <TextRow name={item?.office?.name} textWidth={12} /> },
  { rowText: (item) => <PriceRow info={item?.customerRate} /> },
];

export const ACTION_TYPE = 'GET_LOADS_BY_STATUS';

export const CREDIT_CHECK = 'CREDIT_CHECK';
export const CONSIGNMENT = 'CONSIGNMENT';
export const CONSIGNMENT_REQUEST = 'CONSIGNMENT_REQUEST';


export const onClaimHead = [
  { name: 'displayId', title: 'Load ID', custom: false },
  { name: '', title: 'Start Date', icon: 'date' },
  { name: 'customer', nameRow: 'name', title: 'Customer', custom: false },
  { name: 'agent', nameRow: 'name', title: 'Agent', custom: false },
  { name: 'branch', nameRow: 'name', title: 'Branch', custom: false },
  { name: 'office', nameRow: 'name', title: 'Office', custom: false },
  { name: 'customerRate', title: 'Customer Rate', custom: false, smallSize: 6 },
];

export const onClaimBody = [
  { rowText: (item) => <IconAndTitle icon={Images.loadOutline} title={item?.displayId} /> },
  { rowText: (item) => <DateRow date={item?.createdAt} /> },
  { rowText: (item) => <TextRow name={item?.customer?.name} textWidth={12} /> },
  { rowText: (item) => <TextRow name={item?.agent?.name} textWidth={12} /> },
  { rowText: (item) => <TextRow name={item?.branch?.name} textWidth={12} /> },
  { rowText: (item) => <TextRow name={item?.office?.name} textWidth={12} /> },
  { rowText: (item) => <PriceRow info={item?.customerRate} /> },
];

export const LOAD_DEPARTMENT_ENUMS = {
  PROCESSING: 'PROCESSING',
  RECEIVABLE: 'RECEIVABLE',
  PMT_VERIFICATION: 'PMT_VERIFICATION',
  PAYABLE: 'PAYABLE',
  BILLABLE: 'BILLABLE',
  LOAD_VERIFICATION: 'LOAD_VERIFICATION',
  CONSIGNMENT: 'CONSIGNMENT',
  CREDIT_CHECK: 'CREDIT_CHECK',
  ON_CLAIM: 'ON_CLAIM',
  ON_BOND: 'ON_BOND',
};


export const navigateToLoad = (item, history, list, loadParams, parent) => {
  const currentLoad = item;

  history.push({
    pathname:
      !isAdmin ? `/customer/${currentLoad?.customer?.id}` : `/load/${currentLoad?.id}`,
    state: { loadParams: { ...loadParams }, parent: parent },
  });

};