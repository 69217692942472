import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  EmailValidator,
  useGlobalStyles,
  useGlobalText,
  createInputsWrapper,
  ErrorText,
  FindLoad, FindSuccess, FindErrorItem, PermissionsList, RolePermission,
} from 'utils';
import {
  CreateChancel,
  EditSaveButtons, FormatAddress,
  Line,
  MaskInput,
  SelectInput,
  ValidationInput,
} from 'components';
import { branchActions, httpRequestsOnErrorsActions, httpRequestsOnSuccessActions } from 'store';
import moment from 'moment';

export const CreateBranchInputs = ({ handleChangeName, info }) => {
    const { officesList } = useSelector((state) => ({
      officesList: state.offices.officesList,
    }));
    const globalTextStyle = useGlobalText();
    const globalStyles = useGlobalStyles();
    const globalScreens = createInputsWrapper();
    const dispatch = useDispatch();
    const history = useHistory();
    const [disables, setDisabled] = useState(!!info);
    const [fullAddress, setFullAddress] = useState(info?.address ? info?.address : null);
    const [error, setError] = useState('');
    const [inputs, setInputs] = useState(info ? { ...info } : {});
    const [email, setEmail] = useState('');
    const actionType = info ? 'EDIT_BRANCH' : 'CREATE_BRANCH';
    const loader = FindLoad('CREATE_BRANCH');
    const success = FindSuccess('EDIT_BRANCH');
    const backError = FindErrorItem(actionType);
    const errorText = Array.isArray(backError?.error) ? backError?.error[0] : backError?.error;

    const removeError = () => {
      errorText && dispatch(httpRequestsOnErrorsActions.removeError(actionType));
    };

    const handleChange = e => {
      setInputs(prevState => ({ ...prevState, [e.target.name]: e.target.value }));
      error === e.target.name && setError('');
      e.target.name === 'name' && handleChangeName(e.target.value);
      removeError();
    };

    const handleFullAddress = (ev) => {
      setFullAddress(ev);
      removeError();
      if (error === 'address') setError('');
    };

    const handleCreate = () => {
      const phoneNumber = inputs?.phoneNumber && parseInt(inputs?.phoneNumber.replace(/[^0-9]/g, '')) + '';
      const addressIsValid = fullAddress?.street && fullAddress?.city && fullAddress?.country;

      const data = {
        'name': inputs?.name,
        'officeId': inputs?.officeName,
        'email': inputs?.email,
        'phoneNumber': phoneNumber,
        'establishedDate': new Date(inputs.establishedDate).getTime(),
        'address': fullAddress,
      };
      if (!email.length) {
        if (inputs.name && (info ? true : inputs.officeName) && inputs.email && inputs.phoneNumber && inputs.establishedDate && addressIsValid) {
          if (info) {
            dispatch(branchActions.editBranch(data, info?.id));
          } else {
            dispatch(branchActions.createBranch(data));
          }
        } else {
          const errorMessage = info ?
            !inputs.name ? 'name' :
              !inputs.email ? 'email' :
                !inputs.phoneNumber ? 'phoneNumber' :
                  !inputs.establishedDate ? 'establishedDate' :
                    !addressIsValid ? 'address' : ''
            :
            !inputs.name ? 'name' :
              !inputs.officeName ? 'officeName' :
                !inputs.email ? 'email' :
                  !inputs.phoneNumber ? 'phoneNumber' :
                    !inputs.establishedDate ? 'establishedDate' :
                      !addressIsValid ? 'address' : '';
          setError(errorMessage);
        }
      }
    };

    useEffect(() => {
      return () => {
        removeError();
      };
    }, []);

    useEffect(() => {
      if (success) {
        dispatch(httpRequestsOnSuccessActions.removeSuccess(actionType));
        setDisabled(true);
      }
    }, [success]);

    const handleChancel = () => {
      setDisabled(true);
      setInputs({ ...info });
      setFullAddress(info?.address);
      setError('');
      removeError();
    };

    return (
      <div className={globalScreens.createInputsWrapper}>
        <div className={globalStyles.spaceBetween}>
          <div className={globalStyles.centerItem}>
            {!info && <p className={globalTextStyle.title}>Branch Information</p>}
          </div>
          {info && RolePermission([PermissionsList.CREATE_UPDATE_BRANCH?.code], 'officeManager') &&
            <EditSaveButtons
              margin={true}
              type={'EDIT_BRANCH'}
              handleChancel={handleChancel}
              handleSetEdit={() => setDisabled(false)}
              handleSaveInfo={handleCreate}
            />}
        </div>

        <div className={globalStyles.flexAble}>
          {info && <Line height={'281px'} />}
          <div className={globalStyles.fullWidth}>

            <div className={globalScreens.basicInfo}>
              <p className={globalTextStyle.smallText}>Basic Information</p>
              <div className={globalScreens.basicInfoInputs}>

                <ValidationInput
                  className={info && globalStyles.inputTextFieldBlue}
                  style={globalStyles.simpleInput}
                  variant={'outlined'}
                  name={'name'}
                  type={'text'}
                  label={'Name*'}
                  typeError={error === 'name' ? ErrorText.field : ''}
                  onChange={handleChange}
                  value={inputs.name}
                  disabled={disables}
                />

                {!info &&
                  <SelectInput
                    className={info && globalStyles.inputTextFieldBlue}
                    style={globalStyles.simpleInput}
                    name={'officeName'}
                    label={'Office Name'}
                    handleSelect={handleChange}
                    value={inputs.officeName}
                    list={officesList?.offices}
                    typeError={error === 'officeName' ? ErrorText.field : ''}
                    type={'id'}
                    disabled={disables}
                  />
                }

                <ValidationInput
                  className={info && globalStyles.inputTextFieldBlue}
                  style={globalStyles.simpleInput}
                  validator={EmailValidator}
                  variant={'outlined'}
                  name={'email'}
                  type={'email'}
                  label={'Email Address*'}
                  typeError={error === 'email' ? ErrorText.field : email === 'Not valid email' ? 'Not valid email' : ''}
                  sendBoolean={(bool) => bool === true ? setEmail('Not valid email') : setEmail('')}
                  onChange={handleChange}
                  value={inputs.email}
                  disabled={disables}
                />

                <MaskInput
                  className={info && globalStyles.inputTextFieldBlue}
                  name="phoneNumber"
                  label="Phone Number*"
                  value={inputs.phoneNumber}
                  disables={disables}
                  handleChange={handleChange}
                  info={info}
                  error={
                    error === 'phoneNumber' ? ErrorText.field :
                      errorText === 'phoneNumber must be a valid phone number' && ErrorText.phone}
                />
                <ValidationInput
                  className={info && globalStyles.inputTextFieldBlue}
                  variant={'outlined'}
                  name={'establishedDate'}
                  type={'date'}
                  typeError={error === 'establishedDate' ? ErrorText.field : ''}
                  label={'Establishment Date*'}
                  onChange={handleChange}
                  value={info ? moment(inputs?.establishedDate).format('YYYY-MM-DD') : inputs.establishedDate}
                  disabled={disables}
                />
              </div>
            </div>


            <div className={globalScreens.basicInfo}>
              <p className={globalTextStyle.smallText}>Address</p>
              <div className={globalScreens.basicInfoInputs}>
                <FormatAddress
                  noBlue={!info?.address}
                  setCurrentAddress={handleFullAddress}
                  selectedAddress={info?.address}
                  errorBoolean={error === 'address'}
                  disabled={disables}
                />
              </div>
            </div>
          </div>
        </div>
        {!info &&
          <CreateChancel
            loader={!!loader.length}
            classes={globalStyles.buttonsStyle}
            create={'Create'}
            chancel={'Cancel'}
            onCreate={handleCreate}
            onClose={() => history.push('/branches')}
          />
        }
      </div>
    );
  }
;
