import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  carrierActions,
  httpRequestsOnErrorsActions,
  httpRequestsOnSuccessActions,
} from 'store';
import {
  createInputsWrapper,
  ErrorText,
  FindErrorItem,
  FindSuccessItem, PermissionsList, RolePermission,
  useGlobalStyles,
} from 'utils';
import {
  Line,
  ValidationInput,
  EditSaveButtons,
  SelectInput, FormatAddress,
} from 'components';
import { Classification } from './constants';

const actionType = 'EDIT_W9';

export const WNine = ({ handleChangeName, info }) => {
  const globalStyles = useGlobalStyles();
  const globalScreens = createInputsWrapper();
  const dispatch = useDispatch();
  const [disables, setDisabled] = useState(!!info);
  const [fullAddress, setFullAddress] = useState(info?.w9Info?.address ? info?.w9Info?.address : null);
  const [error, setError] = useState('');
  const [inputs, setInputs] = useState(info?.w9Info ? { ...info?.w9Info  } : {});
  const success = FindSuccessItem(actionType);
  const backError = FindErrorItem(actionType);
  const errorText = backError?.error ? backError?.error : Array.isArray(backError?.error) ? backError?.error[0] : '';

  const removeError = () => {
    errorText && dispatch(httpRequestsOnErrorsActions.removeError(actionType));
  };

  useEffect(() => {
    if (success) {
      dispatch(httpRequestsOnSuccessActions.removeSuccess(actionType));
      setDisabled(true);
    }
  }, [success]);

  useEffect(() => {
    return () => {
      removeError();
    };
  }, []);

  const handleChange = e => {
    setInputs(prevState => ({ ...prevState, [e.target.name]: e.target.value }));
    error === e.target.name && setError('');
    e.target.name === 'companyName' && handleChangeName(e.target.value);
    removeError();
    if (errorText === 'A Carrier with this Authority Number already exists.') {
      dispatch(httpRequestsOnErrorsActions.removeError('CARRIER_AUTHORITY_EXIST'));
    }
  };

  const handleCreate = () => {
    const data = {
      name: inputs?.name,
      businessName: inputs?.businessName,
      classification: inputs?.classification,
      einOrTin: inputs?.einOrTin,
      address: fullAddress,
    };

    if (inputs?.name && inputs.classification && inputs.einOrTin && fullAddress?.street) {
      dispatch(carrierActions.editW9(info?.id, data));
    } else {
      setError(
        !inputs.name ? 'name' :
          !inputs.businessName ? 'businessName' :
            !inputs.classification ? 'classification' :
              !inputs.einOrTin ? 'einOrTin' :
                !fullAddress?.street ? 'address' :
                  '',
      );
    }
  };

  const handleFullAddress = (ev) => {
    setFullAddress(ev);
    removeError();
    if (error === 'address') setError('');
  };

  const handleChancel = () => {
    setDisabled(true);
    setInputs({
      name: info?.w9Info?.name,
      businessName: info?.w9Info?.businessName,
      classification: info?.w9Info?.classification,
      einOrTin: info?.w9Info?.einOrTin,
    });
    setFullAddress(info?.w9Info?.address);
    removeError();
  };


  return (
    <div className={globalScreens.createInputsWrapper}>
      <div className="space-between">
        <div className="align-center" />
        {RolePermission([PermissionsList.CREATE_UPDATE_CARRIER?.code]) &&
          <EditSaveButtons
            margin={true}
            type={actionType}
            handleChancel={handleChancel}
            handleSetEdit={() => setDisabled(false)}
            handleSaveInfo={handleCreate}
          />
        }
      </div>
      <div className={globalStyles.flexAble}>
        <Line height={'auto'} />
        <div className={globalStyles.fullWidth}>
          <div className={globalScreens.basicInfo}>
            <div className={globalScreens.basicInfoInputs}>

              <ValidationInput
                className={info?.w9Info?.name && globalStyles.inputTextFieldBlue}
                style={globalStyles.simpleInput}
                variant={'outlined'}
                name={'name'}
                type={'text'}
                label={'Name*'}
                typeError={error === 'name' ? ErrorText.field : ''}
                onChange={handleChange}
                value={inputs.name}
                disabled={disables}
              />
              <ValidationInput
                className={info?.w9Info?.businessName && globalStyles.inputTextFieldBlue}
                style={globalStyles.simpleInput}
                variant={'outlined'}
                name={'businessName'}
                type={'text'}
                label={'Business Name*'}
                typeError={error === 'businessName' ? ErrorText.field : ''}
                onChange={handleChange}
                value={inputs.businessName}
                disabled={disables}
              />
              <SelectInput
                className={info?.w9Info?.classification && globalStyles.inputTextFieldBlue}
                style={globalStyles.simpleInputNoRight}
                disabled={disables}
                name={'classification'}
                label={'Issuing State*'}
                handleSelect={handleChange}
                value={inputs?.classification}
                list={Classification}
                typeError={error === 'classification' ? ErrorText.field : ''}
                type={'id'}
              />
            </div>

            <div className={globalScreens.basicInfoInputs}>
              <ValidationInput
                className={info?.w9Info?.einOrTin && globalStyles.inputTextFieldBlue}
                style={globalStyles.simpleInput}
                variant={'outlined'}
                name={'einOrTin'}
                type={'text'}
                label={'EIN or TIN Number*'}
                typeError={error === 'einOrTin' ? ErrorText.field : ''}
                onChange={handleChange}
                value={inputs?.einOrTin}
                disabled={disables}
              />

              <div style={{ width: '100%' }}>
                <FormatAddress
                  oneInput={true}
                  noSuite={true}
                  noBlue={!info?.w9Info?.address}
                  setCurrentAddress={handleFullAddress}
                  selectedAddress={info?.w9Info?.address}
                  errorBoolean={error === 'address'}
                  disabled={disables}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <p>{error === 'Input is not field' ? error : ''}</p>
    </div>
  );
};
