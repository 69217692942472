import {
  cleanApDetailReport, cleanApSummaryReport,
  cleanArDetailReport, cleanArSummaryReport,
  getApActivityReport, getApApplication,
  getApDetailReport, getApSummaryReport,
  getArActivityReport,
  getArApplication,
  getArDetailReport,
  getArSummaryReport,
} from './reports.action';
import axios from 'axios';

export { reportsReducer } from './reports.reducer';
export { watchReport } from './reports.saga';

export const reportActions = {
  /** Ar Reports */
  getArDetailReport,
  cleanArDetailReport,
  getArSummaryReport,
  cleanArSummaryReport,
  getArActivityReport,
  getArApplication,
  /** End */

  /** Ap Reports */
  getApDetailReport,
  cleanApDetailReport,
  getApSummaryReport,
  cleanApSummaryReport,
  getApActivityReport,
  getApApplication,
  /** End */
};

export const reportServices = {

  getLoadsReports: (date) => axios.get(`/reports/loads`, { auth: true, params: { ...date } }),

  getLoadTxnReports: (date, loadId) => axios.get(`/loads/${loadId}/txns/csv`, { auth: true, params: { ...date } }),

  getCarriersReports: (date) => axios.get(`/reports/carriers`, { auth: true, params: { ...date } }),

  getCarriersW9Reports: (date) => axios.get(`/reports/carriers/W9`, { auth: true, params: { ...date } }),

  getCustomersReports: (date) => axios.get(`/reports/customers`, { auth: true, params: { ...date } }),

  getReceivablesReports: ( ) => axios.get(`/reports/receivable`, { auth: true }),

  getCustomerPmtReports: (date) => axios.get(`/reports/customerPmt`, { auth: true, params: { ...date } }),

  getCustomerPmtDetailsReports: (date) => axios.get(`/reports/customerPmt/detail`, { auth: true, params: { ...date }, }),

  getCarrierPmtReports: (date) => axios.get(`/reports/customerPmt`, { auth: true, params: { ...date } }),

  getCarrierPmtDetailsReports: (date) => axios.get(`/reports/customerPmt/detail`, { auth: true, params: { ...date }, }),

};