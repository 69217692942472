import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { CreateWrapper, CustomBreadcrumbs } from 'components';
import { agentActions, carrierActions, loadActions, mcActions } from 'store';
import {
  LoadDetails,
  CustomerCredit,
  Destination,
  RequestACarrier,
  DocumentsSection,
  Processing,
} from './core';
import { officeManager, PermissionsList, RolePermission, userType } from 'utils';
import { CANCELLED, CREDITCHECK, DENIED, REJECTED } from '../../../pages/accounting/constants';
import { LoadErrAndNotes } from './core/common/loadErrAndNotes';
import { CONSIGNMENT, CONSIGNMENT_REQUEST } from '../../../pages/credit/constants';
import { LoadSummary } from './core/loadSummary';
import { StopType } from 'utils/types';
import { LoadActions } from './core/loadActions';
import { loadTypeRequireStop } from './core/common/constants';

export const CreateLoadTable = ({ bredcrump }) => {
  const { mcsList, loadById, carriersList, assignedAgentMc, customers } = useSelector((state) => ({
    mcsList: state.mcs.mcsList,
    carriersList: state.carriers.carriersList,
    loadById: state.loads.loadById,
    customers: state.loads.customers,
    assignedAgentMc: state.mcs.assignedAgentMc,
  }));
  const userInfo = JSON.parse(localStorage.getItem('poloUserInfo'));
  const dispatch = useDispatch();
  const params = useParams();
  const [loadDetails, setLoadDetails] = useState(false);
  const [destination, setDestination] = useState(false);
  const [requestACarrier, setRequestACarrier] = useState(false);
  const [documents, setDocuments] = useState(false);
  const [last, setLast] = useState(false);
  const [tonu, setTonu] = useState(loadById && loadById?.isTonu);
  const drop = loadById && loadById?.stops?.length && loadById?.stops.filter((i) => i.type === StopType.DROPOFF);
  const pick = loadById && loadById?.stops?.length && loadById?.stops.filter((i) => i.type === StopType.PICKUP);
  const checkRequiredStops = loadById?.type === loadTypeRequireStop.DRY ||
    loadById?.type === loadTypeRequireStop.REEFER ||
    loadById?.type === loadTypeRequireStop.BOX_TRUCK ||
    loadById?.type === loadTypeRequireStop.POWER ||
    loadById?.type === loadTypeRequireStop.FLATBED ||
    loadById?.type === loadTypeRequireStop.AUTO_CARRIER;

  useEffect(() => {
    dispatch(loadActions.removeSearchedList());
    dispatch(carrierActions.getCarriers({ statuses: ['ACTIVE'] }));
    dispatch(agentActions.getAgents({ status: 'ACTIVE' }));

    if (userType === 'ADMIN' && !loadById) {
      dispatch(mcActions.getMcs({ status: 'ACTIVE' }));
    }
  }, []);

  useEffect(() => {
    if (userInfo && userType !== 'ADMIN' || !officeManager) {
      dispatch(mcActions.getAgentAssignedMcs(userInfo?.id, 'AGENT'));
    }
  }, []);

  useEffect(() => {
    if (loadById && loadById.isTonu === true) {
      setLoadDetails(true);
      setDestination(true);
      setRequestACarrier(true);
      setDocuments(true);
    } else {
      if (loadById?.status !== CREDITCHECK) {
        if (loadById?.mc?.id
          && loadById?.status !== REJECTED
          && loadById?.status !== CONSIGNMENT
          && loadById?.status !== CONSIGNMENT_REQUEST
          && loadById?.status !== DENIED && (loadById?.status === CANCELLED ? loadById?.type : true)
        ) {
          setLoadDetails(true);
        }
        if (loadById?.type) {
          setDestination(true);
        }else{
          setDestination(false);
        }
        if (checkRequiredStops ? (loadById?.stops?.length > 0 && drop?.length && pick?.length) : !!loadById?.type) {
          setRequestACarrier(true);
        }else{
          setRequestACarrier(false);
        }
        if (loadById?.carrier?.id &&  (checkRequiredStops ? (loadById?.stops?.length > 0 && drop?.length && pick?.length) : true) ) {
          setDocuments(true);
        }else{
          setDocuments(false);
        }
      }
    }
  }, [loadById]);

  return (
    <div>
      {bredcrump !== 'noBread' &&
        <CustomBreadcrumbs
          parentLink={'/loads'}
          parent={'Loads'}
          child={'Add Load'}
        />
      }
      <CreateWrapper
        type={params.id ? 'load' : ''}
        noHead={true}
        body={
          <div>
            {loadById?.status !== CANCELLED && <LoadActions loadById={loadById} />}
            <LoadErrAndNotes loadById={loadById} />
            {loadById?.status && <LoadSummary loadById={loadById} />}

            <CustomerCredit
              tonu={tonu}
              info={loadById}
              customers={customers?.customers}
              handleSwitchTonu={() => setTonu(!tonu)}
              mcsListReserve={
                userType === 'ADMIN' ? mcsList?.mcs :
                  assignedAgentMc?.length ? assignedAgentMc :
                    []
              }
              handleChangeScreen={() => setLoadDetails(
                loadById?.status !== 'CREDITCHECK' &&
                loadById?.status !== 'REJECTED' &&
                loadById?.status !== 'CONSIGNMENT' &&
                loadById?.status !== 'CONSIGNMENT_REQUEST' &&
                loadById?.status !== 'DENIED',
              )}
              loadDetails={loadDetails}
            />
            {loadDetails &&
              <LoadDetails
                tonu={tonu}
                info={loadById}
                mcsListReserve={userType === 'ADMIN' ? mcsList?.mcs : assignedAgentMc}
                params={params}
                destination={destination}
                handleChangeScreen={() => setDestination(true)}
              />
            }
            {destination &&
              <Destination
                drop={drop}
                pick={pick}
                info={loadById}
                id={params.id}
                destination={requestACarrier}
                handleChangeScreen={() => setRequestACarrier(true)}
              />
            }
            {requestACarrier &&
              <RequestACarrier
                tonu={tonu}
                carriers={carriersList?.carriers?.filter((i) => i?.doNotLoad === false && (i?.rmisRequired ? i?.rmisStatus === 'ACTIVE' : true))}
                info={loadById}
                id={params.id}
                destination={documents}
                handleChangeScreen={() => setDocuments(true)}
              />
            }
            {documents && RolePermission([PermissionsList.ALL_ACCESS?.code, PermissionsList.UPLOAD_LOAD_DOCUMENT?.code], 'agentView') &&
              <DocumentsSection
                info={loadById}
                id={params.id}
                destination={last}
                andleChangeScreen={() => setLast(true)}
              />
            }
            {loadById && loadById.status === 'PROCESSING' && loadById?.status !== CANCELLED &&
              <Processing info={loadById} />
            }
          </div>
        }
      />
    </div>
  );
};