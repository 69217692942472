import React from "react";
import {AntSwitch, Images, PermissionsList, RolePermission} from "utils";
import {
    DateRow,
    DeleteButton,
    IconAndTitle,
    LinkRow,
    LoadIconAndTitle,
    PriceRow,
    SlicedText,
    TextRow,
    TypeRow
} from "components";
import {TableCell} from "@material-ui/core";

export const company = [
    {name: 'LLC', id: 'LLC'},
    {name: 'Corporation', id: 'CORPORATION'},
    {name: 'DBA', id: 'DBA'},
    {name: '1099 Vendor', id: '1099_VENDOR'},
]

export const docker = [
    {name: 'Mc Number', id: 'MC_NUMBER'},
    // {name: 'Doc Number', id: 'DOT_NUMBER'},
    {name: 'Ca Number', id: 'CA_NUMBER'},
    {name: 'USDOT', id: 'USDOT'},
]

export const loadsHead = [
    {name: 'displayId', title: 'Load ID', custom: false},
    {name: 'createdAt', title: 'Created Date', smallSize: 7, bigSize: 8, icon: 'date', type: 'date'},
    {name: 'customer', nameRow: 'name', title: 'Customer', smallSize: 6, custom: false},
    {name: 'agent', nameRow: 'name', title: 'Agent', custom: false},
    {name: 'branch', nameRow: 'name', title: 'Branch', custom: false},
    {name: 'office', nameRow: 'name', title: 'Office', custom: false},
    {name: 'customerRate', title: 'Customer Rate', custom: false, smallSize: 7, bigSize: 8},
    {name: 'carrierRate', title: 'Carrier Rate', custom: false, smallSize: 7, bigSize: 8},
    {
        name: '', title: 'Status',
        filterList: [
            {name: 'All', id: ''},
            {name: 'In Transit', id: 'INTRANSIT'},
            {name: 'Processing', id: 'PROCESSING'},
            {name: 'Paid', id: 'PAID'},
            {name: 'Denied', id: 'DENIED'},
            {name: 'Rejected', id: 'REJECTED'},
            {name: 'Pending', id: 'PENDING'},
            {name: 'Credit Check', id: 'CREDITCHECK'},
            {name: 'Consingment', id: 'CONSIGNMENT'},
            {name: 'Complete', id: 'COMPLETE'},
        ]
    },
]

export const loadsBody = [
    {rowText: (item) => <LoadIconAndTitle icon={Images.loadOutline} title={item?.displayId} item={item} />},
    // {rowText: (item) => <IconAndTitle icon={Images.loadOutline} title={item?.displayId}/>},
    {rowText: (item) => <DateRow date={item?.createdAt}/>},
    {rowText: (item) => <TextRow name={item?.customer?.name}/>},
    {rowText: (item) => <TextRow name={item?.agent ? item?.agent?.name : 'Not Set'}/>},
    {rowText: (item) => <TextRow name={item?.branch ? item?.branch?.name : 'Not Set'}/>},
    {rowText: (item) => <TextRow name={item?.office ? item?.office?.name : 'Not Set'}/>},
    {rowText: (item) => <PriceRow info={item?.bill?.totalRate}/>},
    {rowText: (item) => <PriceRow info={item?.payable?.totalRate}/>},
    {rowText:  (item) =>
            <p
                className={item?.status === "PROCESSING" ? 'processing-icon-color' :
                    item?.status === "PENDING" ? 'pending-icon-color' :
                        item?.status === "INTRANSIT" ? 'inTransit-icon-color' :
                            item?.status === "REJECTED" ? 'rejected-icon-color' :
                                item?.status === "COMPLETED" ? 'completed-icon-color' :
                                ''}
            >
                {
                    item?.status === "INTRANSIT" ? 'In Transit' :
                        item?.status === "CREDITCHECK" ? 'Credit Check' :
                            item?.status === "CONSIGNMENT_REQUEST" ? 'Consignment Request' :

                                <TypeRow text={item?.status}/>
                }
            </p>
    },
]

export const ACTION_TYPE = 'GET_LOAD_CARRIER'

export const equipmentHead = [
    {
        name: 'driverType', title: 'Driver Type',
        filterList: [
            {name: 'All', id: ''},
            {name: 'Solo', id: 'Solo'},
            {name: 'Team', id: 'Team'},
        ]
    },
    {
        name: 'truckType', title: 'Truck Type',
        filterList: [
            {name: 'All', id: ''},
            {name: 'Solo', id: 'Solo'},
            {name: 'FlatBed', id: 'FlatBed'},
            {name: 'Power Only', id: 'PowerOnly'},
        ]
    },
    {
        name: 'size', title: 'Size',
        filterList: [
            {name: 'All', id: ''},
            {name: '24', id: '24'},
            {name: '48', id: '48'},
            {name: '53', id: '53'},
        ]
    },
    RolePermission([PermissionsList.ALL_ACCESS?.code, PermissionsList.CREATE_UPDATE_CARRIER?.code]) &&
    {name: 'action', title: 'Action', search: 'false', custom: false},
]

export const equipmentBody = [
    {rowText: (item) => <TypeRow text={item?.driverType}/>},
    {rowText: (item) => <TypeRow text={item?.truckType}/>},
    {rowText: (item) => <TextRow name={item?.size + ''}/>},
    {
        button: (item, info, load) =>
            <div>
                {RolePermission([PermissionsList.ALL_ACCESS?.code, PermissionsList.CREATE_UPDATE_CARRIER?.code]) &&
                    <div>{
                        <div className='align-center'>

                            <div className={'align-center'} style={{marginLeft: '10px'}}>
                                <span
                                    className='small-switch-text'>{info?.status === 'ACTIVE' ? 'Activate' : 'Inactivate'}</span>
                                <AntSwitch
                                    onClick={() => item('switch')}
                                    checked={info?.status === 'ACTIVE'}
                                />
                            </div>
                            <DeleteButton toolTipTitle={"Remove Role"} handleClick={() => item('delete')}/>
                        </div>
                    }</div>

                }</div>
        , notClickable: true
    },
]

export const EQUIPMENT_ACTION_TYPE = 'GET_EQUIPMENT'

// Documents

export const carrierUploadDockTypes = [
    {name: 'Proof of insurance', id: 'PROOF_OF_INSURANCE'},
    {name: 'Company Packet', id: 'CARRIERPACKET'},
    {name: 'W9', id: 'W9'},
    {name: 'MC Certificate', id: 'MC_CERTIFICATE'},
    {name: 'Interstate Permit', id: 'INTERSTATE_PERMIT'},
    {name: 'CDL', id: 'CDL'},
    {name: 'EFS Form', id: 'EFS_FORM'},
    {name: 'Payment Form', id: 'PAYMENT_FORM'},
    {name: 'Setup Packet', id: 'SETUPPACKET'},
    {name: 'NOA', id: 'NOA'},
    {name: 'LOR', id: 'LOR'},
    {name: 'Written consent', id: 'WRITTEN_CONSENT'},
    {name: 'Voided check', id: 'VOIDED_CHECK'},
    {name: 'Other', id: "OTHER"},
]

export const carrierDockTypes = [
    {name: 'All Documents', id: 'All DocumentsSection'},
  ...carrierUploadDockTypes,
]


// Collections Table

export const collectionsHead = [
    {name: '', title: 'Load ID', custom: false},
    {name: '', title: 'Amount', custom: false},
    {name: '', title: 'Collected', custom: false},
    {name: '', title: 'Remaining', custom: false},
    {name: '', title: 'Comments', custom: false},
]

export const collectionsBody = [
    {rowText: (item) => <LinkRow id={item?.loadDisplayId} loadId={item?.loadId}>{item?.loadDisplayId}</LinkRow>},
    {rowText: (item) => <PriceRow info={item?.amount ? item?.amount : 0}/>},
    {rowText: (item) => <PriceRow info={item?.collected ? item?.collected : 0}/>},
    {rowText: (item) => <PriceRow info={item?.amount - item?.collected}/>},
    {rowText: (item) => <TextRow name={item?.comment ? item.comment : 'Not Set'}/>},
]


export const lastLoadsHead = [
    {name: '', title: 'Pick Up Date', custom: false,},
    {name: '', title: 'Pick Up State', custom: false,},
    {name: '', title: 'Drop Up Date', custom: false,},
    {name: '', title: 'Drop Up State', custom: false,},
    {name: '', title: 'Carrier Rate', custom: false,},
]

export const lastLoadsBody = [
    {rowText: (item) => <DateRow date={item?.pickupDate}/>},
    {rowText: (item) => <TextRow name={item?.pickupState} />},
    {rowText: (item) => <DateRow date={item?.dropoffDate}/>},
    {rowText: (item) => <TextRow name={item?.dropoffState} />},
    {rowText: (item) => <PriceRow info={+item?.carrierRate }/>},
]

export const LAST_LOADS = 'GET_CARRIER_LAST_LOAD'

export const Classification = [
    {name: 'LLC', id: 'LLC'},
    {name: 'Individual Sole', id: 'INDIVIDUAL_SOLE'},
    {name: 'C Corporation', id: 'C_CORPORATION'},
    {name: 'S Corporation', id: 'S_CORPORATION'},
    {name: 'Partnership', id: 'PARTNERSHIP'},
    {name: 'Trust Estate', id: 'TRUST_ESTATE'},
]