import { Colors } from './globalStylesUi';

export const docTypes = [
  { name: 'Rate Con', id: 'RATECON' },
  { name: 'POD', id: 'POD' },
  { name: 'Invoice', id: 'INVOICE' },
  { name: 'Arrival Notice', id: 'ARRIVAL_NOTICE' },
  { name: 'Demurrage', id: 'DEMURRAGE' },
  { name: 'Pier Pass', id: 'PIER_PASS' },
  { name: 'Carrier Invoice', id: 'CARRIER_INVOICE' },
  { name: 'DO', id: 'DO' },
  { name: 'Per Diem', id: 'PER_DIEM' },
  { name: 'Customs', id: 'CUSTOMS' },
  { name: 'Other', id: 'OTHER' },
];

export const localInfo = localStorage.getItem('userType');

export const ACCOUNT_ENUMS = {
  ADMIN: 'ADMIN',
  AGENT: 'AGENT',
  FACTORED_CARRIER: 'FACTORED_CARRIER',
};
export const userType = localInfo ? localInfo : '';
export const isAgent = userType === ACCOUNT_ENUMS?.AGENT;
export const isFactored = userType === ACCOUNT_ENUMS?.FACTORED_CARRIER;
export const isAdmin = userType === ACCOUNT_ENUMS?.ADMIN;

export const userInfo = JSON.parse(localStorage.getItem('poloUserInfo'));

export const token = localStorage.getItem('access-token');

export const isNotEmpty = (value = '') =>
  typeof value === 'string' ? value.trim() !== '' : value;


// Load Utils
export const CheckEdit = (loadStatus) => {
  const userType = localStorage.getItem('userType');

  if (userType === 'ADMIN') {
    return true;
  } else if (userType === 'AGENT') {
    if (loadStatus === 'PENDING' || loadStatus === 'INTRANSIT') {
      return true;
    } else {
      return false;
    }
  }
};

export const SubmittedLoadStatus = (status) => {
  if (status === 'PROCESSING') {
    return true;
  } else if (status === 'INTRANSIT') {
    return true;
  }
  return false;
};

export const RenderStatus = (status) => {
  return (
    <span style={{ fontSize: '14px', color: Colors.TextSecondary, marginRight: '8px' }}>
           {status === 'Inactivate' ? 'Active' : 'Inactive'}
        </span>
  );
};


export const Status = {
  active: 'ACTIVE',
  inactive: 'INACTIVE',
};

export const RenderEquipmentType = (type) => {
  return type === 'DRYVAN' ? 'Dry Van' :
    type === 'REEFER' ? 'Reefer' :
      type === 'FLATBED' ? 'Flatbed' :
        type === 'POWER_ONLY' ? 'Power Only' :
          type === 'DRAYAGE' ? 'Drayage' :
            type === 'BOX_TRUCK' ? 'Box Truck' :
              type === 'SPRINTER_VAN' ? 'Sprinter Van' :
                type === 'WAREHOUSE' ? 'Warehouse' :
                  type === 'HOT_SHOT' ? 'Hot Shot' :
                    type === 'STEP_DECK' ? 'Step Deck' :
                      type;
};