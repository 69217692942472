import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { factoringActions, httpRequestsOnErrorsActions, httpRequestsOnSuccessActions } from 'store';
import {
  createInputsWrapper, useGlobalText,
  EmailValidator, ErrorText,
  FindErrorItem, FindLoad,
  FindSuccess, PermissionsList,
  RolePermission, useGlobalStyles,
} from 'utils';
import {
  CreateChancel,
  Line,
  ValidationInput,
  EditSaveButtons,
  SelectInput, MaskInput, FormatAddress,
} from 'components';

const paymentMethodList = [
  { name: 'ACH', id: 'ACH' },
  { name: 'Check', id: 'CHECK' },
  { name: 'EFS', id: 'EFS' },
];

export const CreateFactoringInputs = ({ handleChangeName, info }) => {
  const globalText = useGlobalText();
  const globalStyles = useGlobalStyles();
  const globalScreens = createInputsWrapper();
  const dispatch = useDispatch();
  const history = useHistory();
  const [disables, setDisabled] = useState(!!info);
  const [fullAddress, setFullAddress] = useState(info?.address ? info?.address : null);
  const [error, setError] = useState('');
  const [inputs, setInputs] = useState(info ? { ...info } : {});
  const [email, setEmail] = useState('');
  const [remittance, setRemittance] = useState('');
  const actionType = info ? 'EDIT_FACTORING' : 'CREATE_FACTORING';
  const loader = FindLoad('CREATE_FACTORING');
  const success = FindSuccess('EDIT_FACTORING');
  const backError = FindErrorItem(actionType);
  const errorText = Array.isArray(backError?.error) ? backError?.error[0] : backError?.error;

  const removeError = () => {
    errorText && dispatch(httpRequestsOnErrorsActions.removeError(actionType));
  };

  const handleChange = e => {
    setInputs(prevState => ({ ...prevState, [e.target.name]: e.target.value }));
    error === e.target.name && setError('');
    e.target.name === 'name' && handleChangeName(e.target.value);
    removeError();
  };

  const handleFullAddress = (ev) => {
    setFullAddress(ev);
    removeError();
    if (error === 'address') setError('');
  };

  const handleCreate = () => {
    const phoneNumber = inputs?.phone && parseInt(inputs?.phone.replace(/[^0-9]/g, '')) + '';
    const addressIsValid = fullAddress?.street && fullAddress?.city && fullAddress?.country;

    const data = {
      'name': inputs.name,
      'address': fullAddress,
      'pmtMethod': inputs.pmtMethod,
      'phone': phoneNumber,
      'email': inputs.email,
      'remittanceEmail': inputs.remittanceEmail,
      'bankName': inputs.bankName,
      'accountNumber': inputs.accountNumber,
      'routingNumber': inputs.routingNumber,
    };

    if (!remittance.length && !email.length) {
      if (inputs.name && inputs.pmtMethod && inputs.phone && inputs.email && inputs.remittanceEmail && inputs.bankName && inputs.accountNumber && inputs.routingNumber && addressIsValid) {
        if (info) {
          dispatch(factoringActions.editFactoring(info?.id, data));
        } else {
          dispatch(factoringActions.createFactoring(data));
        }
      } else {
        setError(
          !inputs.name ? 'name' :
            !inputs.bankName ? 'bankName' :
              !inputs.accountNumber ? 'accountNumber' :
                !inputs.routingNumber ? 'routingNumber' :
                  !inputs.phone ? 'phone' :
                    !inputs.remittanceEmail ? 'remittanceEmail' :
                      !inputs.email ? 'email' :
                        !inputs.pmtMethod ? 'pmtMethod' :
                          !addressIsValid ? 'address' :
                            'Input is not field',
        );
      }
    }
  };

  useEffect(() => {
    if (success) {
      dispatch(httpRequestsOnSuccessActions.removeSuccess('EDIT_FACTORING'));
      setDisabled(true);
    }
  }, [success]);

  useEffect(() => {
    return () => {
      removeError();
    };
  }, []);

  const handleChancel = () => {
    setDisabled(true);
    setInputs({ ...info });
    setFullAddress(info?.address);
    setError('');
    removeError();
  };

  return (
    <div className={globalScreens.createInputsWrapper}>
      <div className={globalStyles.spaceBetween}>
        <div className={globalStyles.centerItem}>
          {!info && <p className={globalText.title}>Factoring Company Information</p>}
        </div>
        {info && RolePermission([PermissionsList.CREATE_UPDATE_FACTORING?.code]) &&
          <EditSaveButtons
            margin={true}
            type={'EDIT_FACTORING'}
            handleChancel={handleChancel}
            handleSetEdit={() => setDisabled(false)}
            handleSaveInfo={handleCreate}
          />
        }
      </div>

      <div className={globalStyles.flexAble}>
        {info && <Line height={'380px'} />}
        <div className={globalStyles.fullWidth}>
          <div className={globalScreens.basicInfo}>
            <p className={globalText.smallText}>Basic Information</p>
            <div className={globalScreens.basicInfoInputs}>
              <ValidationInput
                className={info && globalStyles.inputTextFieldBlue}
                style={globalStyles.simpleInput}
                variant={'outlined'}
                name={'name'}
                type={'text'}
                label={'Company Name*'}
                typeError={error === 'name' ? ErrorText.field : ''}
                onChange={handleChange}
                value={inputs.name}
                disabled={disables}
              />
              <ValidationInput
                className={info && globalStyles.inputTextFieldBlue}
                style={globalStyles.simpleInput}
                variant={'outlined'}
                name={'bankName'}
                type={'text'}
                label={'Bank Name*'}
                typeError={error === 'bankName' ? ErrorText.field : ''}
                onChange={handleChange}
                value={inputs.bankName}
                disabled={disables}
              />
              <ValidationInput
                className={info && globalStyles.inputTextFieldBlue}
                style={globalStyles.simpleInput}
                variant={'outlined'}
                name={'accountNumber'}
                type={'number'}
                label={'Account Number*'}
                typeError={error === 'accountNumber' ? ErrorText.field : ''}
                onChange={handleChange}
                value={inputs.accountNumber}
                disabled={disables}
              />
              <ValidationInput
                className={info && globalStyles.inputTextFieldBlue}
                variant={'outlined'}
                name={'routingNumber'}
                type={'number'}
                label={'Routing Number*'}
                typeError={error === 'routingNumber' ? ErrorText.field : ''}
                onChange={handleChange}
                value={inputs.routingNumber}
                disabled={disables}
              />
            </div>

            <div style={{ display: 'flex', width: '100%', marginTop: '28px' }}>
              <MaskInput
                name="phone"
                label="Phone Number*"
                value={inputs.phone}
                disables={disables}
                handleChange={handleChange}
                info={info}
                error={
                  error === 'phone' ? ErrorText.field :
                    errorText === 'phone must be a valid phone number' && ErrorText.phone}
              />
              <ValidationInput
                className={info && globalStyles.inputTextFieldBlue}
                style={globalStyles.simpleInput}
                validator={EmailValidator}
                variant={'outlined'}
                name={'remittanceEmail'}
                type={'email'}
                label={'Remittance Email*'}
                typeError={error === 'remittanceEmail' ? ErrorText.field : remittance === 'Not valid email' ? 'Not valid email' : ''}
                sendBoolean={(bool) => bool === true ? setRemittance('Not valid email') : setRemittance('')}
                onChange={handleChange}
                value={inputs.remittanceEmail}
                disabled={disables}
              />
              <ValidationInput
                className={info && globalStyles.inputTextFieldBlue}
                style={globalStyles.simpleInput}
                validator={EmailValidator}
                variant={'outlined'}
                name={'email'}
                type={'email'}
                label={'Email Address*'}
                typeError={error === 'email' ? ErrorText.field : email === 'Not valid email' ? 'Not valid email' : ''}
                sendBoolean={(bool) => bool === true ? setEmail('Not valid email') : setEmail('')}
                onChange={handleChange}
                value={inputs.email}
                disabled={disables}
              />
              <SelectInput
                className={info && globalStyles.inputTextFieldBlue}
                name={'pmtMethod'}
                label={'Payment Method*'}
                handleSelect={handleChange}
                value={inputs.pmtMethod}
                list={paymentMethodList}
                typeError={error === 'pmtMethod' ? ErrorText.field : ''}
                type={'id'}
                disabled={disables}
              />
            </div>
          </div>

          <div className={globalScreens.basicInfo}>
            <p className={globalText.smallText}>Address</p>
            <div className={globalScreens.basicInfoInputs}>
              <FormatAddress
                noBlue={!info?.address}
                setCurrentAddress={handleFullAddress}
                selectedAddress={info?.address}
                errorBoolean={error === 'address'}
                disabled={disables}
              />
            </div>
          </div>
        </div>
      </div>
      {!info &&
        <CreateChancel
          loader={!!loader.length}
          classes={globalStyles.buttonsStyle}
          create={'Create'}
          chancel={'Cancel'}
          onCreate={handleCreate}
          onClose={() => history.push('/factorings')}
        />
      }
    </div>
  );
};
