import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Drawer } from '@material-ui/core';
import { noteActions } from 'store';
import { Images } from 'utils';
import { MoteDrawerModal } from './noteDrawerModal';
import { HtmlTooltip } from '../messages';

export const NoteDrawer = ({ title }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const info = history?.location?.state;
  const [openNotes, setOpenNotes] = useState(false);
  const params = useParams()

  useEffect(() => {
    if(openNotes) {
      getNotes();
    }
  }, [dispatch, openNotes]);

  const getNotes = (loading) => {
    const pushInfo = {
      ...info,
    };
    delete pushInfo.page;
    delete pushInfo.activeTab;
    const load = loading === 'noLoad' ? 'noLoad' : 'load';
    dispatch(noteActions.getGlobalNotes(params?.id, { ...pushInfo }, load));
  };

  return (
    <div>

      <HtmlTooltip
        title={<p>Manage Notes</p>}
        placement="top-start"
      >
        <button className={'reset-btn'} onClick={() => setOpenNotes(true)}>
          <img src={Images.manageNotes} alt={'manageNotes'} />
        </button>
      </HtmlTooltip>

      <Drawer
        className={'note-drawer-modal'}
        anchor={'right'}
        open={openNotes}
        onClose={() => setOpenNotes(false)}
      >
        <MoteDrawerModal
          title={title}
          currentId={params?.id}
          getNotes={getNotes}
          handleClose={() => setOpenNotes(false)}
        />
      </Drawer>
    </div>
  );
};